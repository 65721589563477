import { useState } from 'react'

import { Form } from '@scm/ui-component-form'
import { SearchSection } from './searchSection'
import { SearchBarProps } from './type'
import { searchBar } from './constants'
import { useSearchBar } from './useSearchBar'

export const SearchBar = ({
  classNames,
  defaultValue = '',
  variant = 'header',
  autoFocusInput = true,
  formAction = '/search',
  required = true,
  placeholder = 'Search',
}: SearchBarProps) => {
  const [value, setValue] = useState(defaultValue)

  const { inputRef, formRef, onClickCloseIcon, onChangeInput } = useSearchBar(
    setValue,
    autoFocusInput,
  )

  return (
    <Form
      className={searchBar()}
      data-component="search-bar"
      method="get"
      action={formAction}
      ref={formRef}
    >
      <SearchSection
        classNames={classNames}
        value={value}
        variant={variant}
        required={required}
        placeholder={placeholder}
        onClickCloseIcon={onClickCloseIcon}
        onChangeInput={onChangeInput}
        ref={inputRef}
      />
    </Form>
  )
}
